<template>
  <div>
    <div class="edit-shipping-container">
      <header-back rootClassName="rootClassName1"></header-back>
      <form class="edit-shipping-container1" @submit.prevent="createShipping" autocomplete="off">
        <h1 class="edit-shipping-text">Shipping Address</h1>
        <input-box
          text="Address"
          rootClassName="rootClassName4"
          textinputPlaceholder="ex. Jl. Danau Toba Blok A1 No. 12"
          v-model="address"
        ></input-box>
        <p class="red-text padding-down" v-if="!address && errorValidation">
          Address is required
        </p>
        <div class="flex-1 flex flex-col w-full relative">
          <div class="input-box-text">City</div>
          <Dropdown
            ref="selectCity"
            class="mx-0"
            :disabled="disableCity"
            :maxItem="1000"
            name="city"
            :options="areaList"
            placeholder="Select city"
            v-on:selected="selectedCity"
            autocomplete="false"
          >
          </Dropdown>
          <img
            :src="`/playground_assets/chevron-down.svg`"
            :alt="`image`"
            class="input-select-arrow-down pointer-events-none"
          />
          <p
            class="red-text padding-down"
            v-if="!areaItem.id && errorValidation"
          >
            City is required
          </p>
        </div>
        <div
          class="flex-1 flex flex-col w-full relative"
          @click="disableCity = true"
        >
          <span class="input-box-text">Subdistrict</span>
          <Dropdown
            ref="selectDistrict"
            class="mx-0"
            :maxItem="1000"
            name="kecamatan"
            :options="districtList"
            placeholder="Select subdistrict"
            v-on:selected="selectedDistrict"
            autocomplete="false"
            :class="{ active: isActive }"
            @click="isActive = !isActive"
          >
          </Dropdown>
          <img
            :src="`/playground_assets/chevron-down.svg`"
            :alt="`image`"
            class="input-select-arrow-down pointer-events-none"
          />
          <p
            class="red-text padding-down"
            v-if="!districtItem.id && errorValidation"
          >
            Subdistrict is required
          </p>
        </div>
        <input-box
          rootClassName="rootClassName6"
          text="Zip Code"
          textinputPlaceholder="ex. 12345"
          v-model="zipcode"
        ></input-box>
        <p class="red-text padding-down" v-if="!zipcode && errorValidation">
          Zip Code is required
        </p>
        <button type="submit" class="edit-shipping-btn-login">
          <span class="edit-shipping-text1">
            {{ isSubmiting ? 'Saving...' : 'Save Address' }}
          </span>
        </button>
      </form>
    </div>
    <div v-if="isError" @click="isError = false" class="alert-err">
      <XClose :text="msg_error" />
    </div>
    <div v-if="isSukses" @click="isSukses = false" class="alert-err">
      <XSukses :text="msg_sukses" />
    </div>
    <Footer />
  </div>
</template>

<script>
  import HeaderBack from '../components/header-back';
  import InputBox from '../components/input-box';
  import InputSelect from '../components/input-select';
  import Footer from '../components/footer';
  import Dropdown from 'vue-simple-search-dropdown';
  import Api from '../api';
  import XClose from '../components/x-close';
  import XSukses from '../components/x-sukses';
  import axios from 'axios';

  export default {
    components: {
      HeaderBack,
      InputBox,
      InputSelect,
      Dropdown,
      XClose,
      XSukses,
      Footer,
    },
    data() {
      return {
        address: null,
        areaList: [],
        areaItem: {},
        disableCity: false,
        districtItem: {},
        districtList: [],
        errorValidation: false,
        isError: false,
        isSukses: false,
        msg_error: null,
        msg_sukses: null,
        shipping: {
          id: null,
          city: {
            id: null,
            name: null,
          },
          zipcode: null,
          subdistrict: {
            id: null,
            name: null,
          },
          address: null,
        },
        zipcode: null,
        isSubmiting: false,
        isActive: false,
      };
    },
    methods: {
      editCity() {
        this.selectingCity = true;
        console.log(this.$refs.selectCity);
      },
      async createShipping() {
        try {
          this.isSubmiting = true;
          if (
            !this.address ||
            !this.districtItem.id ||
            !this.areaItem.id ||
            !this.zipcode
          ) {
            this.errorValidation = true;
          } else {
            const res = await axios.post(`${Api.profileShippingUrl}`, {
              city_id: this.areaItem.id,
              subdistrict_id: this.districtItem.id,
              address: this.address,
              zipcode: this.zipcode,
            });
            if (res.data.status) {
              this.$router.push('/my-account');
            } else {
              this.msg_error = res.data.message;
              this.isError = true;
            }
          }
        } catch (error) {
          this.msg_error = error.message;
          this.isError = true;
        } finally {
          this.isSubmiting = false;
        }
      },
      async getArea() {
        const res = await axios.get(Api.areaUrl);
        this.areaList = res.data.map((t) => {
          return { ...t, name: t.city_name };
        });
      },
      async getDistrict(id) {
        const res = await axios.get(`${Api.subdistrictUrl}?city_id=${id}`);
        this.districtList = res.data.map((f) => {
          return { ...f, name: f.subdistrict_name };
        });
      },
      async getShipping() {
        const res = await axios.get(`${Api.profileShippingUrl}`);
        if (res.data.code == 200) {
          const shipping = res.data.data;
          this.shipping = shipping;
          this.address = shipping.address;
          this.areaItem = shipping.city;
          this.districtItem = shipping.subdistrict;
          this.zipcode = shipping.zipcode;
          this.$refs.selectCity.searchFilter = shipping.city.name;
          this.$refs.selectDistrict.searchFilter = shipping.subdistrict.name;
          await this.getDistrict(shipping.city.id);
        }
      },
      selectedCity(city) {
        this.areaItem = city;
        this.zipcode = city.postal_code;
        this.getDistrict(city.id);
      },
      selectedDistrict(item) {
        this.districtItem = item;
        setTimeout(() => {
          this.disableCity = false;
        }, 200);
      },
    },
    mounted() {
      this.getShipping();
      this.getArea();
    },
    name: 'EditShipping',
  };
</script>

<style scoped>
  .alert-err {
    position: fixed;
    right: 20px;
    bottom: 30px;
    width: 300px;
    z-index: 10000;
  }
  .edit-shipping-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    background-color: #eae3d9;
    padding-bottom: 46px;
  }
  .edit-shipping-container1 {
    width: 492px;
    display: flex;
    align-self: center;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .edit-shipping-text {
    color: #1f252c;
    font-family: Lora;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 24px;
  }
  .edit-shipping-btn-login {
    flex: 0 0 auto;
    width: 100%;
    cursor: pointer;
    height: 64px;
    display: flex;
    position: relative;
    margin-top: var(--dl-space-space-unit);
    align-items: center;
    justify-content: flex-start;
    background-color: #1f252c;
  }
  .edit-shipping-text1 {
    color: #fff;
    width: 100%;
    font-size: 18px;
    font-style: normal;
    text-align: center;
    font-family: Montserrat;
    font-weight: 700;
    letter-spacing: 2.4px;
  }
  .input-select-arrow-down {
    right: 20px;
    width: 24px;
    top: 56px;
    position: absolute;
    object-fit: cover;
  }
  @media (max-width: 991px) {
    .edit-shipping-container1 {
      width: 100%;
      margin-top: var(--dl-space-space-threeunits);
      padding-left: var(--dl-space-space-unit);
      padding-right: var(--dl-space-space-unit);
    }
  }
</style>

<style>
  .edit-shipping-container .dropdown input.dropdown-input {
    border-radius: 0;
    padding: 8px 12px;
  }
  .edit-shipping-container .dropdown input.dropdown-input:focus-visible {
    outline: 2px solid #2563eb;
    outline-offset: -2px;
    --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #2563eb;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow);
    border-color: #2563eb;
  }
  .dropdown .dropdown-input {
    background: #fff;
    cursor: pointer;
    width: 100% !important;
    max-width: 100% !important;
    height: 64px !important;
    font-size: 16px !important;
    font-family: Montserrat !important;
    border: 1px solid #1f252c !important;
  }

  .dropdown {
    margin-bottom: var(--dl-space-space-twounits) !important;
  }

  .input-box-text {
    font-size: 18px;
    font-style: normal;
    font-family: Lora;
    font-weight: 700;
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .dropdown .dropdown-content {
    width: 100% !important;
    max-width: 100% !important;
    max-height: auto !important;
    font-size: 16px;
    font-family: Montserrat;
  }
</style>
